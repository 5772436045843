<script>
import TablePage from '@/found/components/table_page';
import Modal from '@/found/components/modal';
import Form from './form';

export default {
  name: 'company_body_list',
  extends: TablePage,
  data() {
    return {
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  components: {
    Modal,
    Form,
  },
  methods: {
    // 按钮点击事件
    modalClick({ val, row }) {
      this.formConfig = {};
      this.formName = 'Form';
      if (val.code === 'add') {
        this.modalConfig.title = '新增';
        this.modalConfig.width = '600px';
        this.modalConfig.height = '380px';
        this.formConfig = {
          type: val.code,
          id: null,
        };
        this.openModal();
      } else if (val.code === 'edit') {
        this.modalConfig.title = '编辑';
        this.modalConfig.width = '600px';
        this.modalConfig.height = '380px';
        this.formConfig = {
          type: val.code,
          id: row.id,
          row: JSON.parse(JSON.stringify(row)),
        };
        this.openModal();
      } else if (val.code === 'view') {
        this.modalConfig.title = '查看';
        this.modalConfig.width = '600px';
        this.modalConfig.height = '380px';
        this.formConfig = {
          ...val,
          type: val.code,
          id: row.id,
          row: JSON.parse(JSON.stringify(row)),
        };
        this.openModal();
      }
    },
  },
  created() {
    this.getConfigList('company_body_list');
  },
};
</script>
