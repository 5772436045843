import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      formFunctionCode: 'company_body_form',
    };
  },

  methods: {

    // 设置规则
    setRule(item) {
      return item;
    },
    // 渲染完成后执行
    formComplete() {
      const { type, id } = this.formConfig;
      if (type === 'edit' || type === 'view') {
        this.setValue(this.formConfig.row);
      }
    },

    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        // 默认新增
        const url = '/mdm/companyInfo/edit';
        // 有id  就是编辑
        if (this.formConfig.id) {
          formData.id = this.formConfig.id;
        }
        request.post(url, formData).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
